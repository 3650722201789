import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, chain, groupBy, toPairs, zipObject, map, includes, filter } from 'lodash';
// Redux
import { connect } from 'react-redux';
import {
  getLocations,
  getUserRoleId,
  getSelectedDepartmentId
} from 'redux/selectors';

import { CALL_API } from 'common/API';
import { size } from 'lodash';
import LocationBasedAttendanceList from './LocationBasedAttendanceList';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9B9B9B;
  height: 385px;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 20px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    
  }
`;

function LocationBasedPresentStaff({
  locationList,
  userRoleId,
  selectedDepartmentId
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] = useState(false);

  const getReportData = async () => {
    toggleReportLoader(true);
    const data = await CALL_API('location-list', 'get');
    if (userRoleId === 1 || userRoleId === 6) {
      let result = chain(data)
        .groupBy(x => x.locationName)
        .map((value, key) => ({ location_name: key, prensentList: value, presentCount: size(value) }))
        .value();
      setReportData(result)
    }
    else {
      let departmentData = filter(data, (x) => includes(selectedDepartmentId, x.departmentId));
      let result = chain(departmentData)
        .groupBy(x => x.locationName)
        .map((value, key) => ({ location_name: key, prensentList: value, presentCount: size(value) }))
        .value();
      setReportData(result)
    }


    toggleReportLoader(false);
  }
  useEffect(() => {
    getReportData();
  }, [selectedDepartmentId]);
  const config = {
    // height:350,
    padding: 'auto',
    data: reportData,
    angleField: "presentCount",
    colorField: 'location_name',
    radius: 0.7,
    label: {
      type: 'inner',
      offset: '-30%',
      autoRotate: false,
      // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: 'center',
      },
    },
    legend: {
      position: 'right',
      layot: 'vertical',
      offsetX : -50,
      itemName: {
        // formatter: (text,item) => {
        //   return " hi all the user interface check nterface"
        // },
        style:{
          fontSize: 11,
        },
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };



  return (
    <>
      <Spin spinning={displayReportLoader}>
        <StyledTitle>LOCATION BASED PRESENT STAFF OVERVIEW</StyledTitle>
        <StyledChartContainer>
          <div className='name-title'>Today's Report:</div>
          {reportData?.length > 0 ? <>
            <Pie {...config}
              onEvent={(plot) => {
                plot.on('element:click', (x) => {
                  setLocationName(x.data.data.location_name)
                  setReportList(x.data.data.prensentList)
                  toggleDisplayAttendanceStaffModal(true)
                  // console.log(x.data.data.location_name)
                });
              }}

            />
          </> : (
            <p style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Staff Present</p>
          )}
        </StyledChartContainer>
      </Spin>
      <LocationBasedAttendanceList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={locationName}
        list={reportList}
        reportType={locationName}
      />
    </>
  );
}

const mapStateToProps = state => ({
  locationList: getLocations(state),
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps, null)(LocationBasedPresentStaff);