import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Spin, Select, DatePicker, Space, Form, Tooltip, Modal, message, Menu, Dropdown } from 'antd';
import Button from 'atoms/Button';
import styled from '@emotion/styled';
// Redux
import { connect } from 'react-redux';
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID
} from 'redux/selectors';
import COLORS from 'common/Colors';
import { CALL_API } from 'common/API';
import { STATUS_CODE } from 'common/Constants';
import { DEVICE } from 'common/Device';
import { CSVLink } from "react-csv";
import AddLocationModal from "organisms/AddLocationModal";
import EditLocationModal from 'organisms/EditLocationModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { reduce, orderBy } from 'lodash';
import Confirm from '../../src/assets/Confirm.svg';
import NotFound from 'organisms/NotFound';

const StyledTable = styled(Table)`
  padding-top: 20px;
  table{
     table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height:500px;
  }
  .ant-table-cell {
    /* padding-left: 20px; */
    /* padding: 10px; */
    height: 50px;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK}
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledButton = styled(Button)`
    width: 125px;
    color: white;
    background-color: #B7B7B7;
    border-radius: 5px; 
    margin-left: 5%;
`;

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 15px;
    padding-top: 3%;
  }
  .ant-modal-footer {
    display: none;
  }
`;

function Location({
  userRoleId,
  selectedDepartmentId,
}) {
  const [addingLocation, toggleAddingLocation] = useState(false);
  const [editingLocation, toggleEditingLocation] = useState(false);
  const [deactivatingLocation, toggleDeactivatingLocation] = useState(false);
  const [confirmdeactivatingLocation, toggleconfirmDeactivatingLocation] = useState(false);
  const [LocationDetails, setLocationDetails] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [CSVLocationList, setCSVLocationList] = useState([]);
  const csvLinkEl = useRef();
  const [showLoader, toggleLoader] = useState(false);
  const [ locateID, setLocateID] = useState();

  const CSVHeaders = [
    { label: "Location ID", key: "location_id" },
    { label: "Location name", key: "LocationName" },
    { label: "No of employee", key: "NoOfEmpolyee" },
    { label: "Description", key: "LocationDescription" },
  ];

  const columns = [
    {
      title: 'Location ID',
      dataIndex: 'location_id',
      key: 'location_id',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Location name',
      dataIndex: 'LocationName',
      key: 'LocationName',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'No of employee',
      dataIndex: 'NoOfEmpolyee',
      key: 'NoOfEmpolyee',
      width: '10%',
      // align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'LocationDescription',
      key: 'LocationDescription',
      width: '20%',
      // align: 'center',
      // ellipsis: true
    },
    {
      // title: 'Action',
      dataIndex: '',
      key: '',
      width: '5%',
      align: 'center',
      render: (_, record) => (
        <Dropdown overlay={<StyledViewMenu>
          <Menu>
            <Menu.Item onClick={() => ViewLocation(record)}>
              Edit
            </Menu.Item>
            <Menu.Item onClick={() => {setLocateID(record.location_id); deletedLocation(true)}}>
              Deactivate
            </Menu.Item>
          </Menu>
        </StyledViewMenu>} trigger={['click']} placement="bottomRight">
          <MoreVertIcon />
        </Dropdown>
      )
    }
  ];

  const addLocation = (leaveObject, isViewOnly = false) => {
    toggleAddingLocation(true);
  }

  const deletedLocation = (leaveObject, isViewOnly = false) => {
    toggleDeactivatingLocation(true);
  }

  useEffect(() => {
    getLocationList();
  }, [setLocationList]);

  const getLocationList = async () => {
    setDisplayLocationLoader(true);
    await CALL_API('admin-location', 'get', {
    }).then(function (response) {
      setLocationList(response);
      setDisplayLocationLoader(false);
    }).catch(function (error) {
      console.log("[getLocationList], Error-->", error);
    })
  }

  const locationfilter = locationList.filter((val) => {
    if (val === "") {
      return val;
    }
    else if(JSON.stringify(val.status_id).toLowerCase().includes(("1").toLowerCase())){
      return val;
    }
  }) 

  const deletelocation = async (location_id) => {
    setLocateID(location_id);
    toggleLoader(true);
    await CALL_API('admin-location', 'delete', {
      'location_id': location_id,
      'status_id': 0
    })
    toggleLoader(false);
    toggleDeactivatingLocation(false);
    toggleconfirmDeactivatingLocation(true);
    getLocationList();
  }

  const prepareCSV = async () => {
    if (locationfilter.length > 0) {
      setCSVLocationList(
        reduce(locationfilter, function (result, list) {
          result.push({
            "Location ID": list.location_id,
            "Location name": list.LocationName,
            "No of Employee": list.NoOfEmpolyee,
            "Description": list.LocationDescription,
          });
          return result;
        }, [])
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  const ViewLocation = (LocationObject, isViewOnly = false) => {
    toggleEditingLocation(true);
    setLocationDetails({
      ...LocationObject,
      isViewOnly
    });
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false
  };

  return (
    <>
      {userRoleId == 5 && (
        <Row className='page-title'>
          <Col span={12} style={{ fontSize: "20px", fontWeight: "bold" }}>List of all Locations</Col>
          <Col span={12} className="align-right">
            <Button type="link" style={{ border: "1px solid", marginBottom:"2%" }} onClick={prepareCSV}>
              Export CSV file
            </Button>
            <CSVLink
              header={CSVHeaders}
              data={CSVLocationList}
              filename={"location-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            <StyledButton type="primary" htmlType='submit' onClick={() => addLocation(true)} style={{ cursor: "pointer" }}>+Add Location</StyledButton>
          </Col>
        </Row>)}

      {userRoleId == 5 && (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            dataSource={locationfilter}
            columns={columns}
            rowClassName={(record, index) => index % 2 === 0 ? '' : 'non-white'}
            rowKey='location_id'
            pagination={locationfilter.length > 20 ? paginationOptions : false}
          />
        </Spin>)}
      {userRoleId != 5 && <NotFound/>}

      {userRoleId == 5 && (
        <AddLocationModal
          isModalVisible={addingLocation}
          closeModal={() => toggleAddingLocation(false)}
          getLocationList={getLocationList}
          LocationDetails={LocationDetails}
        />)}

      {userRoleId == 5 && (
        <EditLocationModal
          isModalVisible={editingLocation}
          closeModal={() => toggleEditingLocation(false)}
          getLocationList={getLocationList}
          LocationDetails={LocationDetails}
        />)}

      {userRoleId == 5 && (
        <StyledModal
          visible={deactivatingLocation}
          width={500}
          onCancel={() => toggleDeactivatingLocation(false)}
          footer={[]}
          centered
        >
          <p style={{ textAlign: "center", fontSize: "16px", marginTop:"5%", marginBottom:"5%" }}>Are you sure you want to deactivate this location?</p>
          <Row>
            <Col className='page-title-head align-right' span={12}>
              <StyledButton type="primary" htmlType='submit' onClick={() => toggleDeactivatingLocation(false)}>No</StyledButton>
            </Col>
            <Col className='page-title-head' span={12}>
              <StyledButton loading={showLoader} type="primary" htmlType='submit' onClick={() => deletelocation(locateID)}>{showLoader ? 'Yes' : 'Yes'}</StyledButton>
            </Col>
          </Row>
        </StyledModal>)}

      {userRoleId == 5 && (
        <StyledModal
          visible={confirmdeactivatingLocation}
          width={500}
          onCancel={() => toggleconfirmDeactivatingLocation(false)}
          footer={[]}
          centered
        >
          <img src={Confirm} height={35} width={35} style={{ marginLeft: "50%", marginBottom: "5%", marginTop: "2%" }} />
          <p style={{ textAlign: "center", fontSize: "16px" }}>The location has been deactivated</p>
        </StyledModal>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
});

export default connect(mapStateToProps)(Location);
