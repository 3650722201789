import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  Tabs,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getUserInfo,
  getLeaveCategoryList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { toggleProcessingModal } from "redux/actions";
import { utils, writeFileXLSX } from 'xlsx';
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  APPROVAL_FILTER_TYPES,
  STATUS_CODE,
} from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  addMonthinDate,
  getMySqlDate,
  momentDate,
  getinitialdate,
} from "utils/Date";
import COLORS from "common/Colors";
import { find, get, concat, uniqBy } from "lodash";

const StyledTable = styled(Table)`
  padding-top: 20px;
  overflow-x: auto;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    max-height: 500px;
    white-space: nowrap;
    &::-webkit-scrollbar {
    cursor: pointer;
    color:gray;
    background-color: gray;
  }
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    min-width: 200px;
    white-space: normal;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 200px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 100%;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
`;

function AuditLog({ userRoleId, selectedDepartmentId }) {
  const [logList, setlogList] = useState([]);
  const [activitylogList, setActivitylogList] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const selectref = useRef(null)


  const columns = [
    {
      title: "Employee Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "15%",
      render: (_, record) => {
        return (
          <>
            {record.firstName} {record.lastName}
          </>
        );
      },
      // align: 'center'
    },
    {
      title: "Login Date",
      dataIndex: "loginDate",
      key: "loginDate",
      width: "15%",
      sorter: (a, b) => moment(a.loginDate).diff(moment(b.loginDate)),
    },
    {
      title: "Logout Date",
      dataIndex: "logoutDate",
      key: "logoutDate",
      width: "15%",
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(loginDateTime.getTime() + 9 * 60 * 60 * 1000);
    
        if (record.logoutTime === "" && sessionTime >= new Date(record.logoutExpiresTime)) {
          return moment(record.loginDate).format('YYYY-MM-DD');
        } else {
          return moment(record.logoutDate).format('YYYY-MM-DD'); 
        }
      },
      sorter: (a, b) => {
        const dateA = moment(a.logoutTime === "" ? a.loginDate : a.logoutDate);
        const dateB = moment(b.logoutTime === "" ? b.loginDate : b.logoutDate);
    
        return dateA.diff(dateB);
      },
    },
    
    {
      title: "Login Time",
      dataIndex: "loginTime",
      key: "loginTime",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Login IP Address",
      dataIndex: "loginipAddress",
      key: "loginipAddress",
      width: "15%",
      // align: 'center'
    },
    {
      title: "Login Source",
      dataIndex: "loginDeviceName",
      key: "loginDeviceName",
      width: "15%",
      // align: 'center'
    },
    {
      title: "Login Platform",
      dataIndex: "loginPlatform",
      key: "loginPlatform",
      width: "15%",
      // align: 'center'
    },
    {
      title: "Login Browser Name",
      dataIndex: "loginBrowserName",
      key: "loginBrowserName",
      width: "15%",
      // align: 'center'
    },
    {
      title: "Logout Time",
      dataIndex: "logoutTime",
      key: "logoutTime",
      width: "10%",
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(
          loginDateTime.getTime() + 9 * 60 * 60 * 1000
        );
        if (
          record.logoutTime === "" &&
          sessionTime >= new Date(record.logoutExpiresTime)
        ) {
          return record.logoutExpiresTime;
        } else {
          return record.logoutTime;
        }
      },
    },
    {
      title: "Logout Platform",
      dataIndex: "logoutPlatform",
      key: "logoutPlatform",
      width: "15%",
      // align: 'center'
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(
          loginDateTime.getTime() + 9 * 60 * 60 * 1000
        );
        if (
          record.logoutTime === "" &&
          sessionTime >= new Date(record.logoutExpiresTime)
        ) {
          return record.loginPlatform;
        } else {
          return record.logoutPlatform;
        }
      },
    },
    {
      title: "Logout Browser Name",
      dataIndex: "logoutBrowserName",
      key: "logoutBrowserName",
      width: "15%",
      // align: 'center'
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(
          loginDateTime.getTime() + 9 * 60 * 60 * 1000
        );
        if (
          record.logoutTime === "" &&
          sessionTime >= new Date(record.logoutExpiresTime)
        ) {
          return record.loginBrowserName;
        } else {
          return record.logoutBrowserName;
        }
      },
    },
    {
      title: "Logout IP Address",
      dataIndex: "logoutipAddress",
      key: "logoutipAddress",
      width: "15%",
      // align: 'center'
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(
          loginDateTime.getTime() + 9 * 60 * 60 * 1000
        );
        if (
          record.logoutTime === "" &&
          sessionTime >= new Date(record.logoutExpiresTime)
        ) {
          return record.loginipAddress;
        } else {
          return record.logoutipAddress;
        }
      },
    },
    {
      title: "Logout Source",
      dataIndex: "logoutDeviceName",
      key: "logoutDeviceName",
      width: "15%",
      render: (_, record) => {
        const loginDateTime = new Date(record.loginDateTime);
        const sessionTime = new Date(
          loginDateTime.getTime() + 9 * 60 * 60 * 1000
        );
        if (
          record.logoutTime === "" &&
          sessionTime >= new Date(record.logoutExpiresTime)
        ) {
          return record.loginDeviceName;
        } else {
          return record.logoutDeviceName;
        }
      },
    },
  ];

  const activitylogcolumns = [
    {
      title: "Employee Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "15%",
      render: (_, record) => {
        return (
          <>
            {record.firstName} {record.lastName}
          </>
        );
      },
      // align: 'center'
    },
    {
      title: "Action Date",
      dataIndex: "actionDate",
      key: "actionDate",
      width: "15%",
      // align: 'center',
      sorter: (a, b) => moment(a.actionDate).diff(moment(b.actionDate)),
    },
    {
      title: "Action Time",
      dataIndex: "actionTime",
      key: "actionTime",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Action Name",
      dataIndex: "action",
      key: "action",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Action Ip Address",
      dataIndex: "actionipAddress",
      key: "actionipAddress",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Action Platform",
      dataIndex: "actionOs",
      key: "actionOs",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Action Browser Name",
      dataIndex: "actionBrowserName",
      key: "actionBrowserName",
      width: "15%",
      // align: 'center',
    },
    {
      title: "Action Device Name",
      dataIndex: "actionDeviceName",
      key: "actionDeviceName",
      width: "15%",
      // align: 'center',
    },
  ];

  useEffect(() => {
    getLogList();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("duration_type"),
  ]);

  const getLogList = async () => {
    return new Promise(async (resolve, reject) => {
      setlogList([]);
      const { start_date, duration_type, month, end_date } =
        form.getFieldsValue();
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );

      setDisplayTaskLoader(true);
      const { code, data } = await CALL_API("session-log", "post", {
        start_date: getMySqlDate(
          duration_type === 2
            ? startDateOfMonth
            : duration_type === 4
            ? momentDate(addMonthinDate(getinitialdate()))
            : start_date
        ),
        end_date: getMySqlDate(
          duration_type === 2
            ? lastDateOfMonth
            : duration_type === 3
            ? weeks(7, getMySqlDate(start_date))
            : duration_type === 4
            ? momentDate(addMonthinDate(getKenyanDateTime(), 3))
            : end_date
        ),
      });
      if(code === STATUS_CODE.SUCCESS){
        setDisplayTaskLoader(false);
        setlogList(data.reverse());
      }
    });
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById('Table');
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "Activitylog-report.xlsx");
  });

  const SessionLogreportxport = React.useCallback(() => {
    const elt = document.getElementById('SessionTable');
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "Sessionlog-report.xlsx");
  });

  const getActivityLogList = async () => {
    return new Promise(async (resolve, reject) => {
      setActivitylogList([]);
      const { startdate, durationtype, monthname, enddate } =
        form.getFieldsValue();
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: monthname }), "index", 0)
      );

      setDisplayTaskLoader(true);
      const { code, data } = await CALL_API("activity-log", "post", {
        start_date: getMySqlDate(
          durationtype === 2
            ? startDateOfMonth
            : durationtype === 4
            ? momentDate(addMonthinDate(getinitialdate()))
            : startdate
        ),
        end_date: getMySqlDate(
          durationtype === 2
            ? lastDateOfMonth
            : durationtype === 3
            ? weeks(7, getMySqlDate(startdate))
            : durationtype === 4
            ? momentDate(addMonthinDate(getKenyanDateTime(), 3))
            : enddate
        ),
      });
      if(code === STATUS_CODE.SUCCESS){
      setDisplayTaskLoader(false);
      setActivitylogList(data.reverse());
      }
    });
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };
  const validateDateTimeRange = (date) => {
    const endDate = moment(form.getFieldValue("enddate"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  function weeks(days, date) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date.toISOString().slice(0, 10);
  }

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 20,
    showSizeChanger: false,
  };
  const filterloglist = form.getFieldValue("staff_name")
    ? logList?.filter(
        (data) => data?.userId === form.getFieldValue("staff_name")
      )
    : logList;

  const STAFF_LIST = uniqBy(logList, "userId").map((val) => ({
    user_id: val.userId,
    name: `${val.firstName} ${val.lastName}`,
  }));

  const sortedStaffList = [...STAFF_LIST];
  sortedStaffList.sort((a, b) => a.name.localeCompare(b.name));

  const filterActivityloglist = form.getFieldValue("staffname")
    ? activitylogList?.filter(
        (data) => data?.userId === form.getFieldValue("staffname")
      )
    : activitylogList;

  const ACTIVITY_STAFF_LIST = uniqBy(activitylogList, "userId").map((val) => ({
    user_id: val.userId,
    name: `${val.firstName} ${val.lastName}`,
  }));

  const activityStaffList = [...ACTIVITY_STAFF_LIST];
  activityStaffList.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    getActivityLogList();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("endate"),
    form.getFieldValue("monthname"),
    form.getFieldValue("startdate"),
    form.getFieldValue("enddate"),
    form.getFieldValue("durationtype"),
  ]);

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Session Log" key="1">
          <Row className="page-title">
            <Col span={12} className="page-title-head">
              Session Log
            </Col>
          </Row>
          <br />
          <Row className="page-title">
            <Col xs={24} sm={19} md={19} className="page-title-head">
              <Space>
                <StyledApprovalSearchForm
                  form={form}
                  name="approval_form"
                  layout="inline"
                  initialValues={{
                    staff_name: "",
                    duration_type: 4,
                    month: dateInDetail(getKenyanDateTime()).monthName,
                    start_date: momentDate(
                      addMonthinDate(getKenyanDateTime(), -1)
                    ),
                    end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                  }}
                  onFinish={getLogList}
                >
                  <Form.Item
                    name="duration_type"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select..."
                      style={{
                        width: 172,
                      }}
                      options={APPROVAL_FILTER_TYPES}
                    />
                  </Form.Item>

                  <Form.Item className="outer-form" shouldUpdate>
                    {({ getFieldValue }) => {
                      const duration_type = getFieldValue("duration_type");
                      return duration_type === 1 ? (
                        <>
                          <Row>
                            <Col ref={selectref}>
                              <Form.Item
                                name="start_date"
                                rules={[
                                  () => ({
                                    validator(rule, value) {
                                      const date = moment(value);
                                      return date.isValid()
                                        ? Promise.resolve()
                                        : Promise.reject();
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: 172,
                                  }}
                                  placeholder="Start date"
                                  onChange={validateDateRange}
                                  allowClear={false}
                                  getPopupContainer={() => selectref.current}
                                />
                              </Form.Item>
                            </Col>
                            <Col ref={selectref}>
                              <Form.Item
                                name="end_date"
                                rules={[
                                  () => ({
                                    validator(rule, value) {
                                      const date = moment(value);
                                      return date.isValid()
                                        ? Promise.resolve()
                                        : Promise.reject();
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: 172,
                                  }}
                                  placeholder="End date"
                                  onChange={validateDateRange}
                                  allowClear={false}
                                  getPopupContainer={() => selectref.current}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : duration_type === 3 ? (
                        <Form.Item
                          name="start_date"
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid()
                                  ? Promise.resolve()
                                  : Promise.reject();
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                            style={{
                              width: 172,
                            }}
                            placeholder="Start date"
                            onChange={validateDateRange}
                            allowClear={false}
                          />
                        </Form.Item>
                      ) : duration_type === 2 ? (
                        <Form.Item
                          name="month"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 172,
                            }}
                            placeholder="Select..."
                            options={MONTH_LIST}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    name="staff_name"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option.name ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Select Employee"
                      options={concat(
                        {
                          user_id: "",
                          name: "All Employee",
                        },
                        STAFF_LIST
                      )}
                      fieldNames={{
                        label: "name",
                        value: "user_id",
                      }}
                    />
                  </Form.Item>
                </StyledApprovalSearchForm>
              </Space>
            </Col>
            <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={SessionLogreportxport} disabled={filterloglist.length === 0}>
            Export XLSX file
          </StyledButton>
        </Col>
          </Row>

          <Spin spinning={displayTaskLoader}>
            <StyledTable
              dataSource={filterloglist}
              columns={columns}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "" : "non-white"
              }
              pagination={filterloglist.length > 20 ? paginationOptions : false}
              id="SessionTable"
            />
          </Spin>
        </TabPane>

        <TabPane tab="Activity Log" key="2">
          <Row className="page-title">
            <Col span={12} className="page-title-head">
              Activity Log
            </Col>
          </Row>
          <br />
          <Row className="page-title">
            <Col xs={24} sm={19} md={19} className="page-title-head">
              <Space>
                <StyledApprovalSearchForm
                  form={form}
                  name="approval_form"
                  layout="inline"
                  initialValues={{
                    staffname: "",
                    durationtype: 4,
                    monthname: dateInDetail(getKenyanDateTime()).monthName,
                    startdate: momentDate(
                      addMonthinDate(getKenyanDateTime(), -1)
                    ),
                    enddate: momentDate(addDaysinDate(getKenyanDateTime())),
                  }}
                  onFinish={getLogList}
                >
                  <Form.Item
                    name="durationtype"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select..."
                      style={{
                        width: 172,
                      }}
                      options={APPROVAL_FILTER_TYPES}
                    />
                  </Form.Item>

                  <Form.Item className="outer-form" shouldUpdate>
                    {({ getFieldValue }) => {
                      const durationtype = getFieldValue("durationtype");
                      return durationtype === 1 ? (
                        <>
                          <Row>
                            <Col>
                              <Form.Item
                                name="startdate"
                                rules={[
                                  () => ({
                                    validator(rule, value) {
                                      const date = moment(value);
                                      return date.isValid()
                                        ? Promise.resolve()
                                        : Promise.reject();
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: 172,
                                  }}
                                  placeholder="Start date"
                                  onChange={validateDateTimeRange}
                                  allowClear={false}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                name="enddate"
                                rules={[
                                  () => ({
                                    validator(rule, value) {
                                      const date = moment(value);
                                      return date.isValid()
                                        ? Promise.resolve()
                                        : Promise.reject();
                                    },
                                  }),
                                ]}
                              >
                                <DatePicker
                                  style={{
                                    width: 172,
                                  }}
                                  placeholder="End date"
                                  onChange={validateDateTimeRange}
                                  allowClear={false}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : durationtype === 3 ? (
                        <Form.Item
                          name="startdate"
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid()
                                  ? Promise.resolve()
                                  : Promise.reject();
                              },
                            }),
                          ]}
                        >
                          <DatePicker
                            style={{
                              width: 172,
                            }}
                            placeholder="Start date"
                            onChange={validateDateTimeRange}
                            allowClear={false}
                          />
                        </Form.Item>
                      ) : durationtype === 2 ? (
                        <Form.Item
                          name="monthname"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 172,
                            }}
                            placeholder="Select..."
                            options={MONTH_LIST}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    name="staffname"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option.name ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Select Employee"
                      options={concat(
                        {
                          user_id: "",
                          name: "All Employee",
                        },
                        ACTIVITY_STAFF_LIST
                      )}
                      fieldNames={{
                        label: "name",
                        value: "user_id",
                      }}
                    />
                  </Form.Item>
                </StyledApprovalSearchForm>
              </Space>
            </Col>
            <Col xs={24} sm={4} md={4} className='align-right'>
          <StyledButton onClick={xport} disabled={filterActivityloglist.length === 0}>
            Export XLSX file
          </StyledButton>
        </Col>
          </Row>

          <Spin spinning={displayTaskLoader}>
            <StyledTable
              dataSource={filterActivityloglist}
              columns={activitylogcolumns}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "" : "non-white"
              }
              // pagination={filterActivityloglist.length > 20 ? paginationOptions : false}
              id='Table'
            />
          </Spin>
        </TabPane>
      </Tabs>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  leaveCategoryList: getLeaveCategoryList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(AuditLog);
