import React from "react";
import { ReactComponent as Dashboard } from "assets/dashboard.svg";
import { ReactComponent as Approvals } from "assets/approvals.svg";
import { ReactComponent as Calendar } from "assets/calendar.svg";
import { ReactComponent as Team } from "assets/team.svg";
import { ReactComponent as Report } from "assets/report.svg";
import { ReactComponent as Attendance } from "assets/attendance.svg";
import { ReactComponent as Leave } from "assets/leave.svg";
import { ReactComponent as Break } from "assets/break.svg";
import { ReactComponent as Overtime } from "assets/overtime.svg";
import { ReactComponent as Location } from "assets/location.svg";
import { ReactComponent as Logout } from "assets/logout.svg";
import { ReactComponent as Notification } from "assets/notification.svg";
import { ReactComponent as Play } from "assets/play.svg";
import { ReactComponent as Calendar2 } from "assets/calendar_2.svg";
import { ReactComponent as StopBreak } from "assets/stop_break.svg";
import { ReactComponent as Task } from "assets/task.svg";
import { ReactComponent as Document } from "assets/documents.svg";
import { ReactComponent as Chat } from "assets/chat.svg";
import { ReactComponent as BabyCarrier } from "assets/baby_carrier.svg";
import { ReactComponent as PregnantWoman } from "assets/pregnant_woman.svg";
import { ReactComponent as MedicalBox } from "assets/medical_box.svg";
import { ReactComponent as Broadcast } from "assets/broadcast.svg";
import { ReactComponent as Upload } from "assets/upload.svg";
import { ReactComponent as Employee } from "assets/employee.svg";
import { ReactComponent as AddEmployee } from "assets/add_employee.svg";
import { ReactComponent as Xl } from "../assets/excel1.svg";
import { ReactComponent as Word } from "../assets/word.svg";
import { ReactComponent as Pdf } from "../assets/pdf.svg";
import { ReactComponent as Image } from "../assets/img.svg";
import { ReactComponent as Video } from "../assets/video.svg";
import { ReactComponent as Zip } from "../assets/zip.svg";
import { ReactComponent as Ppt } from "../assets/ppt.svg";
import { ReactComponent as Department } from "assets/department.svg";
import { ReactComponent as Exceptionreport } from "../assets/exceptionreport.svg";
import { ReactComponent as Earlycheckout } from "../assets/earlychekout.svg";
import { ReactComponent as Latecheckin } from "../assets/latecheckin.svg";
import { ReactComponent as Absentreport } from "../assets/absentreport.svg";
import { ReactComponent as Role } from "../assets/role.svg";
import { ReactComponent as Log } from "../assets/roles.svg";

const styleIcon = {
  height: '20px'
}

const iconTypes = {
  dashboard: Dashboard,
  employee: Employee,
  addemployee: AddEmployee,
  approvals: Approvals,
  calendar: Calendar,
  team: Team,
  report: Report,
  attendance: Attendance,
  leave: Leave,
  break: Break,
  overtime: Overtime,
  location: Location,
  logout: Logout,
  notification: Notification,
  play: Play,
  calendar2: Calendar2,
  stop_break: StopBreak,
  task: Task,
  document: Document,
  chat: Chat,
  pregnant_woman: PregnantWoman,
  baby_carrier: BabyCarrier,
  medical_box: MedicalBox,
  broadcast: Broadcast,
  upload: Upload,
  xl: Xl,
  word: Word,
  pdf: Pdf,
  image: Image,
  video: Video,
  zip: Zip,
  ppt: Ppt,
  department: Department,
  earlycheckout: Earlycheckout,
  latecheckin: Latecheckin,
  absentreport: Absentreport,
  exceptionreport: Exceptionreport,
  role: Role,
  Log: Log,

};

const Icon = ({ name, ...props }) => {
  let Icon = iconTypes[name];
  return <Icon style={styleIcon} {...props} />;
};

export default Icon;