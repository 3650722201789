import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Checkbox,
} from "antd";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import moment from "moment";
import { getMySqlDate, momentDate } from "utils/Date";
import { concat } from "lodash";
import { NON_EMPTY_REGEX, STATUS_CODE, VALIDATE_SYMBOL } from "common/Constants";
import { getAllDepartmentList, getLocations } from "redux/selectors";
import { bindActionCreators } from 'redux';
import {
	toggleProcessingModal,
} from 'redux/actions';
import { useDropzone } from "react-dropzone";
import { get, includes } from "lodash";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    color: #1b1b1b;
  }
  .anticon {
    color: #000000;
    width: 14px;
    height: 14px;
  }
  .ant-modal-close {
    margin-top: 14px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-btn-text:focus,
  .ant-btn-text:hover {
    background: #5b86e5;
    color: #ffffff;
  }
`;

const StyledDropzone = styled.div`
  text-align: center;
  width: 100%;
  height: 40px;
  background: #e3e3e3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    padding-top: 10px;
  }
`;

const StyledButton = styled(Button)`
  background: #5b86e5;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

function EditEmployee({
  isModalVisible,
  closeModal,
  getEmployeeList,
  allDepartmentList,
  EmployeeDetails,
  EmployeeList,
  updateProcessingModal
}) {
const empIdList = EmployeeList?.map((user) => user.EmployeeID);
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const containerRef = useRef(null);
  const [filename, setFileName] = useState("");
  const [profileUploaded, setProfileUploaded] = useState(false);
  const emailList = EmployeeList?.map((user) => user.EmailId).filter(
    (email) => email != EmployeeDetails.EmailId
  );
  const [uniqueId, setUniqueId] = useState(generateUniqueId());
  const [storeId, setStoreId] = useState("");
  const [profileStatus, setProfileStatus] = useState(false);
  useEffect(() => {
    const {
      EmployeeID = "",
      EmailId = "",
      first_name = "",
      last_name = "",
      start_date = "",
      end_date = "",
      NHIF = "",
      NSSF = "",
      KRA_pin = "",
      national_Id = "",
      username = "",
      overtime_status = "",
      salary = "",
      profile_url = "",
      rest_days = "",
      sick_leave_halfpay = "",
      roleId = "",
      staffDepartmentId = "",
      hodDepartmentId = "",
      date_of_birth = "",
      departmentId = ""
    } = EmployeeDetails;
    form.setFieldsValue({
      employee_id: EmployeeID,
      first_name: first_name,
      last_name: last_name,
      email_id: EmailId,
      username: username,
      gender_name: "",
      start_date: moment(start_date),
      end_date: end_date ? moment(end_date) : "",
      national_id: national_Id,
      date_of_birth: moment(date_of_birth),
      nssf_id: NSSF,
      nhif_id: NHIF,
      kra_pin: KRA_pin,
      overtime: overtime_status,
      salary: salary,
      sick_leave_halfpay: sick_leave_halfpay === "yes" ? true : false,
      rest_days: rest_days === "yes" ? true : false,
      profile_url:
        profile_url != ("" || null || undefined) ? profile_url : null,
      staff_department_id: roleId == 6 ? departmentId : staffDepartmentId,
      hod_department_id: hodDepartmentId,
      role_id: roleId,
    });
  }, [EmployeeDetails]);
  
  function generateUniqueId() {
    const usedIds = [];
    let uniqueId = "";
    while (uniqueId.length !== 6) {
      const randomNumber = Math.floor(Math.random() * 900000) + 100000;
      if (!usedIds.includes(randomNumber.toString())) {
        usedIds.push(randomNumber.toString());
        uniqueId += randomNumber.toString();
      }
    }
    return uniqueId;
  }

  useEffect(() => {}, [storeId]);

  const onFinish = async (values) => {
    toggleLoader(true);
    const {
      employee_id,
      staff_department_id,
      hod_department_id,
      first_name,
      last_name,
      email_id,
      username,
      role_id,
      start_date,
      end_date,
      national_id,
      nssf_id,
      nhif_id,
      kra_pin,
      overtime,
      salary,
      rest_days,
      sick_leave_halfpay,
      date_of_birth,
      
    } = values;
    
    await CALL_API("admin-employee", "patch", {
      userId: EmployeeDetails.userId,
      employeeId: employee_id,
      first_name: first_name,
      last_name: last_name,
      email_id: email_id,
      username: username,
      role_id: role_id,
      start_date: getMySqlDate(start_date),
      end_date: end_date ? getMySqlDate(end_date) : "",
      NSSF: nssf_id,
      NHIF: nhif_id,
      KRApin: kra_pin,
      NationalId: national_id,
      status_id: 1,
      staff_department_id: staff_department_id,
      hod_department_id: hod_department_id,
      overtime_status: overtime,
      salary: salary,
      date_of_birth: getMySqlDate(date_of_birth),
      sick_leave_halfpay: sick_leave_halfpay == true ? "yes" : "no",
      rest_days: rest_days == true ? "yes" : "no",
      shift_start_time: EmployeeDetails.shift_start_time,
      shift_end_time:EmployeeDetails.shift_end_time,
      shift_type: EmployeeDetails.shift_type,
      profile_url: profileStatus === true ? `https://tymelusprofile-test.s3.amazonaws.com/${employee_id}/${storeId}.jpg` : EmployeeDetails?.profile_url,
    });
    toggleLoader(false);
    setFileName("");
    form.resetFields();
    closeModal(true);
    getEmployeeList();
  };

  const Reset = () => {
    form.resetFields();
    setFileName("");
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (form.getFieldValue("employee_id")) {
      updateProcessingModal(
        true
      )
      acceptedFiles.map((file) => {
        const reader = new FileReader();
        reader.onload = async function (e) {
          if (
            includes(
              [
                "image/jpeg",
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/tif",
                "image/tiff",
                "png",
                "jpg",
                "tif",
              ],
              get(file, "type", "")
            )
          ) {
            setFileName(acceptedFiles[0].path);
            setProfileUploaded(true);
            setUniqueId(generateUniqueId());
           

            const { code } = await CALL_API("profile-upload", "post", {
              userid: form.getFieldValue("employee_id"),
              filetype: get(file, "type", ""),
              content: e.target.result,
              filename: uniqueId,
            });
            setStoreId(uniqueId);
            if(code ===  STATUS_CODE.SUCCESS){
              setProfileStatus(true);
              updateProcessingModal(false);
            }
          } else {
            setProfileUploaded(false);
            message.error("Please upload valid image file.");
          }
        };
        reader.readAsDataURL(file);
        return file;
      });
    }
  }, [acceptedFiles]);

  const validateEmployeeId = (rule, value) => {
    if ( value != EmployeeDetails.EmployeeID && value && empIdList.includes(value)) {
      return Promise.reject("Employee ID already exist");
    }
    return Promise.resolve();
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const validateEmail = (rule, value) => {
    if (value && emailList.includes(value.trim())) {
      return Promise.reject("This Email is already registered");
    }
    return Promise.resolve();
  };

  return (
    <>
      <StyledModal
        title="Edit Employees"
        open={isModalVisible}
        onCancel={() => {
          closeModal(false);
          setFileName("");
          form.resetFields();
        }}
        width={600}
        footer={[]}
      >
        <Form
          form={form}
          name="editemployeeform"
          layout="vertical"
          initialValues={
            {
              // department_name: EmployeeDetails.departmentId,
              // role_id: EmployeeDetails.roleId,
            }
          }
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="employee_id"
                label="Employee ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Employee ID",
                  },
                  { validator: validateEmployeeId },
                ]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
            </Col>
            {EmployeeDetails.roleId !== 6 &&(
              <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="role_id"
                label="Role"
              >
                <Select
                  placeholder="Select role"
                  options={[
                    {
                      value: 1,
                      label: "Super HOD",
                    },
                    {
                      value: 2,
                      label: "HOD",
                    },
                    {
                      value: 3,
                      label: "ASSISTANT HOD",
                    },
                    {
                      value: 4,
                      label: "STAFF",
                    },
                  ]}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            )}
            
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
              
                name="staff_department_id"
                label="Staff Department"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter staff department name",
                  },
                ]}
              >
                <Select
                disabled={EmployeeDetails.roleId == 6 }
                  options={concat(
                    {
                      department_id: "",
                      department_name: "Select Staff Department",
                    },
                    allDepartmentList
                  )}
                  fieldNames={{
                    label: "department_name",
                    value: "department_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            {(form.getFieldValue("role_id") == 2 ||
              form.getFieldValue("role_id") == 3) && (
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="hod_department_id"
                  label=" Hod Department"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Please enter hod department name",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    options={allDepartmentList}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    defaultValue={[1, 2, 3]}
                    placeholder="Select Hod Department"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="first_name"
                label="First name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter First name",
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: "Name should not contain special characters",
                  },
                ]}
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="last_name"
                label="Last name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Last name",
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: "Name should not contain special characters",
                  },
                ]}
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter Username",
                  },
                  // {
                  //   required: false,
                  //   pattern: VALIDATE_SYMBOL,
                  //   message: "Name should not contain special characters",
                  // },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="email_id"
                label="Email ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter EmailID",
                  },
                  { validator: validateEmail },
                ]}
              >
                <Input placeholder="Email ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Start date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter start date!",
                  },
                  () => ({
                    validator(rule, value) {
                      const date = moment(value);
                      return date.isValid()
                        ? Promise.resolve()
                        : Promise.reject();
                    },
                  }),
                ]}
              >
                <DatePicker
                  // disabledDate={(current) => restrictPastDate(current, 'start_date')}
                  onChange={validateDateRange}
                  placeholder="Select start date"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                name="overtime"
                label="Overtime Eligible"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please Select",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  getPopupContainer={() => containerRef.current}
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.start_date !== currentValues.start_date
                }
              >
                {() => (
                  <Form.Item label="End date" name="end_date">
                    <DatePicker
                      // disabledDate={(current) => restrictPastDate(current, 'end_date')}
                      placeholder="Select end date"
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} ref={containerRef}>
              <Form.Item
                label="Date of Birth"
                name="date_of_birth"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter date of birth!",
                  },
                ]}
              >
                <DatePicker
                 disabled={true}
                  onChange={validateDateRange}
                  placeholder="Select date of birth"
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="salary"
                label=" Salary"
                // rules={[
                //   {
                //     required: true,
                //     pattern: NON_EMPTY_REGEX,
                //     message: 'Please enter salary',
                //   },
                // ]}
              >
                <Input placeholder="Salary" type="number" />
              </Form.Item>
            </Col>
            
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="national_id"
                label="National ID"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NationalID",
                  },
                ]}
              >
                <Input placeholder="National ID" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="nssf_id"
                label="NSSF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NSSF",
                  },
                ]}
              >
                <Input placeholder="NSSF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="nhif_id"
                label="NHIF"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter NHIF",
                  },
                ]}
              >
                <Input placeholder="NHIF" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="kra_pin"
                label="KRA Pin"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Please enter KRA Pin",
                  },
                ]}
              >
                <Input placeholder="KRA Pin" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-2">
              <Row>
                <Col xs={24} sm={24} md={12} className="">
                  <Form.Item name="sick_leave_halfpay" valuePropName="checked">
                    <Checkbox>Sick Leave(half-pay)</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="">
                  <Form.Item name="rest_days" valuePropName="checked">
                    <Checkbox>Rest Days</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="profile" label="Profile Pic">
                <StyledDropzone {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>{filename !== "" ? <>{filename}</> : <> choose file</>}</p>
                </StyledDropzone>
              </Form.Item>
            </Col>
          </Row>
          <div className="align-center">
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              {showLoader ? "Update" : "Update"}
            </StyledButton>
          </div>
        </Form>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  allDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
	{
		updateProcessingModal: toggleProcessingModal,
	},
	dispatch
);

export default connect(mapStateToProps,mapDispatchToProps,null)(EditEmployee);
