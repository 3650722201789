import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Spin, Select } from "antd";
import styled from "@emotion/styled";
import LeaveDonutChart from "./LeaveDonutChart";
import { LEAVE_ICON, LEAVE_INFO } from "common/Constants";
import { CSVLink } from "react-csv";
import COLORS from "common/Colors";
import Button from "atoms/Button";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getLeaveCategoryList,
  getUserList,
  getAllDepartmentList,
} from "redux/selectors";
import { find, get, map, reduce, includes, concat, uniqBy } from "lodash";
import { dateInDetail, getKenyanDateTime } from "utils/Date";
import { CALL_API } from "common/API";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  float: right;
`;

const StyledDepartmentTitle = styled.div`
  padding-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
  width: 100%;
`;

function DepartmentStaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
}) {
  const [title, setTitle] = useState("");
  const [userLeaves, setUserLeaves] = useState([]);
  const [departmentUsers, setDepartmentUsers] = useState([]);
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);
  const leaveDataArray = [
    {
      type: LEAVE_INFO.AVAILABLE,
      value: 0,
      color: get(COLORS, `LEAVE.${leaveTypeId}.AVAILABLE`, ""),
      lastYearLeaveBalance: 0,
    },
    {
      type: LEAVE_INFO.CONSUMED,
      value: 0,
      color: get(COLORS, `LEAVE.${leaveTypeId}.CONSUMED`, ""),
    },
  ];
  const [filteredUsers, setFilteredUsers] = useState('');
  const [APIResponse, setAPIResponse] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  useEffect(() => {
    
    if (displayModal) {
      const leaveName = get(
        find(leaveCategoryList, { leave_category_id: leaveTypeId }),
        "leave_category_name",
        ""
      );

      
      const departmentNameList = [];

      selectedDepartmentId.map((id) => {
        departmentNameList.push(
          get(
            find(allDepartmentList, { department_id: id }),
            "department_name",
            ""
          )
        );
      });
      setTitle(`${departmentNameList} department's ${leaveName ? leaveName: leaveTypeId == 3 ? 'RESTDAYS' : 'SICK LEAVE(HALF-PAY)'} Balance`);


      const users = [];
      const tmpDepartmentUsers = [];
      map(userList, ({ user_id, name, department_id }) => {
        if (includes(selectedDepartmentId, department_id)) {
          users.push({
            user_id,
            name,
            leaveData: leaveDataArray,
          });
          tmpDepartmentUsers.push({
            user_id,
            name,
          });
        }
      });
      setUserLeaves(uniqBy(users, "user_id"));
      setDepartmentUsers(uniqBy(tmpDepartmentUsers, "user_id"));
      getReportData();
      setAPIResponse({
        availableList: [],
        consumedList: [],
      });
    }
  }, [displayModal,filteredUsers]);

  useEffect(() => {
    const data = departmentUsers.reduce((result, user) => {
      if (
        filteredUsers.length === 0 ||
        filteredUsers === user.user_id
      ) {
        const dupeleaveDataArray = JSON.parse(
          JSON.stringify(leaveDataArray)
        );
        const availableUserInfo = APIResponse.availableList.find(
          (item) => item.user_id === user.user_id
        );
        const consumedUserInfo = APIResponse.consumedList.find(
          (item) => item.user_id === user.user_id
        );
        if (availableUserInfo) {
          const total_leaves = availableUserInfo.total_leaves ;
          const lastYearLeave = availableUserInfo?.previousYearLeaveBalance || 0;
  
          let previousYearLeave = parseFloat(
            lastYearLeave - consumedUserInfo?.leaves_consumed || 0
          );
  
          // if (previousYearLeave >= 0) {
          //   dupeleaveDataArray[0].value = total_leaves;
          //   dupeleaveDataArray[0].lastYearLeaveBalance = lastYearLeave;
          // } else {
          //   dupeleaveDataArray[0].value =
          //     total_leaves + previousYearLeave;
          //   dupeleaveDataArray[0].lastYearLeaveBalance = 0;
          // }
          dupeleaveDataArray[0].value = (total_leaves - (consumedUserInfo?.leaves_consumed ? consumedUserInfo.leaves_consumed : 0)) + (Number(lastYearLeave) < 0 ? Number(lastYearLeave) : 0);
          dupeleaveDataArray[1].value = consumedUserInfo?.leaves_consumed || 0;
  
          if (
            dupeleaveDataArray[0].value !== 0 ||
            dupeleaveDataArray[1].value !== 0
          ) {
            result.push({
              user_id: user.user_id,
              name: user.name,
              leaveData: dupeleaveDataArray,
            });
          }
        }
      }
      return result;
    }, []);
    setUserLeaves(data);
  }, [filteredUsers, APIResponse]);
  

  const getReportData = async () => {
    toggleLeaveReportLoader(true);
    const data = await CALL_API("leave-service", "post", {
      method: "userWiseOverallTotalLeavesAvailableAndConsumed",
      parameters: {
        leave_type: leaveTypeId,
        year: dateInDetail(getKenyanDateTime()).year,
        department_id: selectedDepartmentId,
      },
    });
    let availableList = [];
    let consumedList = [];
    data.map((list) => {
      Object.entries(list).forEach(([key, value]) => {
        availableList = concat(availableList, value.available);
        consumedList = concat(consumedList, value.consumed);
      });
    });

    setAPIResponse({
      availableList,
      consumedList,
    });
    toggleLeaveReportLoader(false);
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];

  const prepareCSV = async (event, done) => {
    if (userLeaves.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          userLeaves,
          function (result, leave) {
            result.push({
              "Employee ID": leave.user_id,
              Name: leave.name,
              "Available Leaves": leave.leaveData[0].value,
              "Consumed Leaves": leave.leaveData[1].value,
              "Total Leaves":
                leave.leaveData[0].value + leave.leaveData[1].value,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
    }
  };

  return (
    <>
      <StyledModal
        title={title}
        visible={displayModal}
        onCancel={()=>{setFilteredUsers('');toggleModal()}}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={24}>
              <StyledSelect
                // mode="multiple"
                showSearch
                placeholder="Search Staff"
                name="user"
                optionFilterProp="name"
                filterOption={(input, option) =>
                  option.name.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.name
                    .toLowerCase()
                    .localeCompare(optionB.name.toLowerCase());
                }}
                // options={departmentUsers}
                options={concat({
                  name: 'All Users',
                  user_id: ''
                }, departmentUsers)}
                fieldNames={{
                  label: "name",
                  value: "user_id",
                }}
                value={filteredUsers}
                onChange={(newValue) => setFilteredUsers(newValue)}
              />
            </Col>
          </Row>
          <Row className="justify-content-flex-end">
            <StyledButton3
              onClick={prepareCSV}
              disabled={userLeaves.length === 0}
            >
              Export CSV file
            </StyledButton3>
            <CSVLink
              header={CSVHeaders}
              data={CSVDepartmentLeave}
              filename={"Department Leave List.csv"}
              asyncOnClick={true}
              ref={csvLinkDepartment}
            />
          </Row>
          <Row gutter={16}>
            {map(userLeaves, ({ name, leaveData }, index) => (
              <StyledCol md={12} sm={24} xs={24} key={index}>
                <StyledDepartmentTitle>
                  <b>{name}</b>
                </StyledDepartmentTitle>
                <LeaveDonutChart
                  iconName={LEAVE_ICON[leaveTypeId]}
                  title={leaveName}
                  data={leaveData}
                  staffLeave={leaveName}
                />
              </StyledCol>
            ))}
          </Row>
        </Spin>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  leaveCategoryList: getLeaveCategoryList(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
});

export default connect(mapStateToProps, null)(DepartmentStaffLeaveBalance);
